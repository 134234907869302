import { Loader } from '@module/mdrt-org/shared/components';
import {
  type CustomDialogProps,
  Dialog as DialogBase,
  withLoaderDialogComponent,
} from '@shared/ui-components';

export const Dialog = (props: Omit<CustomDialogProps, 'loader'>) =>
  withLoaderDialogComponent(DialogBase, {
    ...props,
    loader: <Loader isInner />,
  });
