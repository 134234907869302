import { auth } from '@shared/misc';
import { logErrorMessage } from '@shared/utils';

import { useUserContext } from '../../components/providers/user-context';
import { useRouter } from '../../i18n/i18n-navigation';
import { ERROR_PATH } from '../constants/local-paths';
import { withErrorHandling, getApiHeaders } from '../helpers';
import { fetchData } from '../helpers/fetch-data';
import { getPublicEnvironmentVariables } from '../helpers/get-public-environment-variables';
import { type CustomerProfile } from '../types';

import { OrgSharedDataTag } from './constants/tags';

export const getCustomerProfile = async ({
  personId,
}: {
  personId?: number;
}): Promise<CustomerProfile> => {
  const session = await auth();

  return withErrorHandling(
    await fetchData({
      apiUrl: `${process.env.NEXT_PUBLIC_API_BASE_URL}/${process.env.NEXT_PUBLIC_API_API_ROUTE}/CustomerProfile/${personId}`,
      headers: getApiHeaders(session),
      next: {
        tags: [
          OrgSharedDataTag.GET_CUSTOMER_PROFILE,
          `${OrgSharedDataTag.GET_CUSTOMER_PROFILE}_${personId}`,
        ],
      },
    }),
    // eslint-disable-next-line no-template-curly-in-string
    '${process.env.NEXT_PUBLIC_API_BASE_URL}/${process.env.NEXT_PUBLIC_API_API_ROUTE}/CustomerProfile/${personId}',
    true
  );
};

export const useGetNullableCustomerProfile = () => {
  const { accessToken } = useUserContext();
  const router = useRouter();

  return {
    getNullableCustomerProfile: async ({
      personId,
    }: {
      personId?: number;
    }): Promise<CustomerProfile | null> => {
      const environment = await getPublicEnvironmentVariables();

      const response = await fetch(
        `${environment.apiBaseUrl}/${environment.apiRoute}/CustomerProfile/${personId}`,
        {
          headers: {
            Authorization: accessToken ? `Bearer ${accessToken}` : '',
            'Ocp-Apim-Subscription-Key': environment.ocpApiSubscriptionKey ?? '',
          },
        }
      );

      if (response.status === 404) {
        return null;
      }

      if (response?.status !== 200) {
        logErrorMessage(
          `${response?.url} returned status ${response?.status}, ${await response?.text()}`,
          [
            // eslint-disable-next-line no-template-curly-in-string
            '${environment.apiBaseUrl}/${environment.apiRoute}/CustomerProfile/${personId}',
            response?.status?.toString() ?? '0',
          ]
        );

        router.push(ERROR_PATH);
      }

      return await response?.json();
    },
  };
};
